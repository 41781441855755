<template>
  <section>
    <transactions-main />
  </section>
</template>

<script>
export default {
  name: 'Transactions',
  components: {
    TransactionsMain: () => import('@/components/Transactions/TransactionsMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
